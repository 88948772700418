.login_container {
    font-family: "Arial", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    /* Warna orange cerah */
    color: #333;
    width: 100%;
}

.login_card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login_card h2 {
    text-align: center;
    color: #333;
}

.login_card form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

input {
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    outline: none;
    color: #333;
}

input:focus {
    border-color: #ff8c00;
    /* Orange color on focus */
}

.login_buttonsa {
    background-color: #ff8c00;
    /* Bright orange color */
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.login_buttonsa:hover {
    background-color: #ff7f00;
    /* Slightly darker orange on hover */
}