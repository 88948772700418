.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0,0.5); /* Dark overlay */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content-change {
	background-color: white;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
	width: 500px;
	max-width: 90%;
	position: relative;
}

.modal-close {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
}

.modal-close:hover {
	color: red;
}

.modal-content h2 {
	margin-bottom: 20px;
	font-size: 24px;
}

.modal-content ul {
	list-style-type: none;
	padding: 0;
}

.modal-content ul li {
	margin-bottom: 10px;
}
