.main-container {
    margin-top: 85px;
}

.students-container {
    font-family: "Poppins", sans-serif;
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.student-card {
    background: linear-gradient(135deg, #1e3c72 0%, #2a5298 50%, #764ba2 100%);
    color: white;
    width: 320px;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s, box-shadow 0.3s;
}

.sa-card {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sa-card .student-name {
    font-size: 27px;
    text-align: center;
}

.student-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.student-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.student-name {
    font-size: 22px;
    font-weight: bold;
}

.student-status {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.student-status.paid {
    background: linear-gradient(135deg, #34e89e 0%, #0f3443 100%);
}

.student-status.unpaid {
    background: linear-gradient(135deg, #ff7e5f 0%, #d72d3a 100%);
}

/* From Uiverse.io by mrhyddenn */
.shadow__btn {
    padding: 10px 20px;
    border: none;
    font-size: 10px;
    color: #fff;
    border-radius: 7px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    transition: 0.5s;
    transition-property: box-shadow;
}

.shadow__btn {
    background: rgb(0, 140, 255);
    box-shadow: 0 0 15px rgb(0, 140, 255);
}

.shadow__btn:hover {
    box-shadow: 0 0 5px rgb(0, 140, 255), 0 0 7px rgb(0, 140, 255),
        0 0 10px rgb(0, 140, 255), 0 0 20px rgb(0, 140, 255);
    cursor: pointer;
}

.no-students {
    font-size: 1.2rem;
    padding-bottom: 1rem;
}

/* Detail.css */

.participation-details {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-container {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-container input[type="text"],
.search-container select {
    font-size: 18px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
}

.search-container input[type="text"]:focus,
.search-container select:focus {
    border-color: #007bff;
    outline: none;
}

.search-container button {
    font-size: 18px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-container button:hover {
    background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .search-container {
        flex-direction: column;
        align-items: stretch;
    }

    .search-container input[type="text"],
    .search-container select,
    .search-container button {
        font-size: 16px;
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
    }

    .search-container button {
        margin-bottom: 0;
    }

    .sa-card {
        width: 100%;
    }
}

@media (max-width: 480px) {

    .search-container input[type="text"],
    .search-container select,
    .search-container button {
        font-size: 14px;
        padding: 8px;
    }

    .sa-card {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .student-card {
        width: calc(50% - 20px);
        /* 2 cards per row */
    }


}

@media (min-width: 768px) {
    .student-card {
        width: calc(33.33% - 20px);
        /* 3 cards per row */
    }

    .sa-card {
        width: 40%;
    }

}

@media (min-width: 992px) {
    .student-card {
        width: calc(25% - 20px);
        /* 4 cards per row */
    }

    .sa-card {
        width: 40%;
    }

}