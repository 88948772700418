@media (max-width: 500px) {
    .laptop-para {
        display: none;
    }
}

@media (min-width: 500px) {
    .mobile-para {
        display: none;
    }
}



@media (max-width: 500px) {
    body {
        overflow-x: hidden;
    }
}

@media (max-width: 500px) {
    #about{
        display: inline;
    }
}